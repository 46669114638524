import styled from "styled-components";

export const Container = styled.header`
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  box-shadow: 0px 1px 0px #00000014;
  display: flex;
  justify-content: space-between;
  padding: 0 140px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  -webkit-backdrop-filter: blur(20px);

  img {
    padding: 12px;
    width: 184px;
  }

  section {
    align-items: center;
    display: flex;

    nav {
      display: flex;
      margin-right: 40px;

      > a,
      button {
        align-items: center;
        display: flex;
        gap: 14px;
        padding: 26px 24px;
        color: #444444;

        svg {
          transition: all 0.3s ease-out;
        }

        :hover {
          background: rgba(255, 255, 255, 0.1);

          svg {
            transform: rotate(-180deg);
          }
        }
      }
      .services {
        :hover {
          ul {
            visibility: visible;
          }
        }
        ul {
          visibility: hidden;
          list-style: none;
          position: absolute;
          top: 76px;
          right: 250px;
          padding: 14px 16px;
          display: flex;
          flex-direction: column;
          gap: 28px;
          box-shadow: 0px 4px 8px #00000029;
          background-color: #fff;
        }
      }
    }

    .icons-container {
      button {
        height: 72px;
        width: 64px;

        :hover {
          background-color: #f4f4f4;
        }
      }
    }
  }

  .whatsapp {
    align-items: center;
    color: #444444;
    display: flex;
    gap: 8px;

    :hover {
      color: #e6423d;
      text-decoration: underline;
    }
  }
`;
