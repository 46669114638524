import { useState } from "react";
import * as icon from "../../assets/icons";
import * as images from "../../assets/images";
import * as S from "./styles";
import { Card } from "./Card";
import { DropDownContainer } from "../../containers/DropDown";
import { Popup } from "./Popup";
import { Header } from "../../containers/Header";

export type TPopup = "card1" | "card2" | "card3" | "card4" | "card5" | "card6";

export const Home = () => {
  const [selectPopup, setSelectPopup] = useState<TPopup>();

  const handleScroll = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href");
    const targetElement = document.querySelector(targetId as string);
    if (targetElement instanceof HTMLElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 76,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Header handleScroll={handleScroll} />
      <S.Container>
        <section className="first-section">
          <div>
            <h2>Crie vantagens competitivas</h2>
            <p>
              A Indicador Consultores é uma empresa de consultoria em Gestão de
              Negócios que propõe soluções específicas para cada cliente,
              embasadas em melhores práticas, conhecimento científico e uma
              experiência de 30 anos de atuação em diversificados negócios,
              mercados e segmentos.
            </p>
            {/* <a href="quem-somos" className="anchor-with-background">
            Quem somos <icon.ArrowRight />
          </a> */}
          </div>
        </section>
        <nav>
          <a href="#card-1" onClick={handleScroll}>
            <icon.IconEstrategia />
            Estratégia empresarial
            <icon.ChevronDown className="chevron-down" />
          </a>
          <a href="#card-2" onClick={handleScroll}>
            <icon.IconInteligenciaComp />
            Inteligência competitiva
            <icon.ChevronDown className="chevron-down" />
          </a>
          <a href="#card-3" onClick={handleScroll}>
            <icon.IconPessoas />
            Gestão estratégica com pessoas
            <icon.ChevronDown className="chevron-down" />
          </a>
          <a href="#card-4" onClick={handleScroll}>
            <icon.IconMarketing />
            Marketing estratégico
            <icon.ChevronDown className="chevron-down" />
          </a>
          <a href="#card-5" onClick={handleScroll}>
            <icon.IconArquiOrg />
            Design organizacional
            <icon.ChevronDown className="chevron-down" />
          </a>
          <a href="#card-6" onClick={handleScroll}>
            <icon.IconGestaoPerformance />
            Gestão da performance
            <icon.ChevronDown className="chevron-down" />
          </a>
        </nav>
        <section className="second-section">
          <div>
            <hr />
            <section className="second-section-main">
              <section className="analysis-and-planning">
                <h2>Análise e planejamento estratégico</h2>
                <img
                  className="first-image"
                  src={images.buildingImage}
                  alt="imagem de um prédio"
                />
                <p>
                  A Indicador utiliza métodos cientificamente fundamentados, que
                  delineiam com acurácia os resultados dos negócios de cada
                  cliente.
                </p>
              </section>
              <section className="what-we-do">
                <div>
                  <section style={{ marginRight: 40 }}>
                    <h6>O que fazemos</h6>
                    <p>
                      A Indicador é pioneira na aplicação de metodologias que
                      facilitam o gerenciamento e o desdobramento da sua
                      estratégia empresarial.
                    </p>
                  </section>
                  <section>
                    <h6>Como fazemos</h6>
                    <p>
                      Pesquisamos e desenvolvemos análises e indicadores de
                      desempenho que otimizam a tomada de decisões da sua
                      empresa.
                    </p>
                    {/* <a href="quem-somos" className="learn-more">
                    Saiba mais <icon.ArrowRight />
                  </a> */}
                  </section>
                </div>
                <img
                  className="second-image"
                  src={images.abstractImage}
                  alt="imagem abstrata"
                />
              </section>
            </section>
          </div>
          {/* <section className="last-section">
            <div>
              <p>
                Avaliamos a sua atuação no mercado, objetivos e oportunidades de
                crescimento, por meio da coleta de informações e diagnósticos
                construídos sobre bases científicas que asseguram vantagens
                competitivas a cada um de nossos clientes.
              </p>
              <a href="quem-somos" className="learn-more">
                Sobre a Indicador® <icon.ArrowRight />
              </a>
            </div>
          </section> */}
        </section>
        <section>
          <Card
            backgroundImage={images.pexelsPixabay}
            title="Reoriente e alinhe os processos a sua estratégia competitiva"
            Icon={icon.IconEstrategia}
            description="O planejamento estratégico conduzido pela Indicadores reorienta e alinha os processos de negócios da sua empresa a partir da sua estratégia competitiva."
            id="card-1"
            openPopup={setSelectPopup}
          />
          <Card
            backgroundImage={images.pexelsSteveJohnson}
            title="Prospecte as tendências e cenários"
            Icon={icon.IconInteligenciaComp}
            description="A Inteligência competitiva identifica e analisa de maneira crítica e científica as informações internas e externas, prospectando tendências e simulando cenários que cercam os ambientes do seu negócio."
            id="card-2"
            openPopup={setSelectPopup}
          />
          <Card
            backgroundImage={images.adobeStock}
            title="Desenvolva competências estratégicas"
            Icon={icon.IconPessoas}
            description="A gestão estratégica com pessoas soma novas experiências e conhecimentos. Desenvolva as competências necessárias e facilite a transformação das suas práticas diárias para obter os melhores resultados para sua empresa."
            id="card-3"
            openPopup={setSelectPopup}
          />
          <Card
            backgroundImage={images.pexelsYusufp}
            title="Analise as necessidades do seu mercado"
            Icon={icon.IconMarketing}
            description="A gestão estratégica da força de vendas, parte da dinâmica dos mercados e dos clientes, que impõe um permanente realinhamento estratégico e mercadológico da gestão da força de vendas e dos resultados esperados."
            id="card-4"
            openPopup={setSelectPopup}
          />
          <Card
            backgroundImage={images.pexelsRostislavUzunov}
            title="Explore o potencial dos seus recursos organizacionais"
            Icon={icon.IconArquiOrg}
            description="O Design organizacional visa o alinhamento entre a estratégia, processos, competências, estrutura organizacional e recompensas, potencializando a utilização dos recursos e a produtividade organizacional."
            id="card-5"
            openPopup={setSelectPopup}
          />
          <Card
            backgroundImage={images.pexelsMoEid}
            title="Equilibre suas ações e resultados"
            Icon={icon.IconGestaoPerformance}
            description="A Gestão da Performance visa o monitoramento das tendências e dos resultados nos processos de negócios da sua empresa."
            id="card-6"
            openPopup={setSelectPopup}
          />
        </section>
        <DropDownContainer />
      </S.Container>
      {selectPopup && (
        <Popup openPopup={setSelectPopup} selectPopup={selectPopup} />
      )}
    </>
  );
};
