import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { Services } from "../pages/Services";

export const RenderRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/quem-somos" element={<AboutUs />} /> */}
      {/* <Route path="/artigo" element={<Articles />} /> */}
      {/* <Route path="/entre-em-contato" element={<ContactUs />} /> */}
      <Route path="/services" element={<Services />} />
      {/* <Route path="/centro-de-informação" element={<InformationCenter />} /> */}
    </Routes>
  );
};
