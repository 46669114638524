/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as icon from "../../assets/icons";
import * as S from "./styles";
import { DropDownContainer } from "../../containers/DropDown";

const Card = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <S.CardContainer>
      <h6>{title}</h6>
      <hr />
      <div>
        <p>{description}</p>
        {/* <a href="quem-somos" className="learn-more">
          Saiba mais <icon.ArrowRight />
        </a> */}
      </div>
    </S.CardContainer>
  );
};

// const ClientCard = ({ image }: { image: string }) => {
//   return (
//     <S.ClientCard>
//       <img src={image} />
//       <div className="card-description">
//         <div>
//           <h6>Baterias XYZ</h6>
//           <icon.ArrowRight />
//         </div>
//         <hr />
//         <p>
//           Através da implementação de uma gestão estratégica focada em inovação
//           e qualidade, a empresa de baterias XYZ aumentou sua produtividade em
//           30% e melhorou sua participação de mercado em 20%.
//         </p>
//       </div>
//     </S.ClientCard>
//   );
// };

export const Services = () => {
  const [searchParams] = useSearchParams();
  const selectedType = Number(searchParams.get("selected")?.split("-")[1]);
  const [selected, setSelected] = useState(selectedType ? selectedType - 1 : 0);
  const navTitleTabs = [
    "Todos",
    "Estratégia empresarial",
    "Inteligência competitiva",
    "Gestão de pessoas",
    "Marketing estratégico",
    "Design organizacional",
    "Gestão da performance",
  ];

  const next = () => {
    setSelected((prev) => (prev === 5 ? 0 : prev + 1));
  };

  const previous = () => {
    setSelected((prev) => (prev === 0 ? 5 : prev - 1));
  };

  return (
    <S.Container>
      <div className="page-title">
        <h1>Serviços</h1>
      </div>
      <section className="business-strategy">
        {selected === 0 && (
          <section>
            <h2>
              <icon.IconEstrategia />
              {navTitleTabs[1]}
            </h2>
            <div className="cards-container">
              <Card
                title="Metodologia"
                description="O processo de construção do Planejamento Empresarial conduzido
              pela Indicador alinha e reorienta os processos de negócios da
              empresa, por meio do desdobramento e monitoramento mensal das
              metas através de indicadores e análise dos resultados
              efetivamente planejados e alcançados."
              />
              <Card
                title="Objetivos"
                description="Reorientação estratégica e organizacional."
              />
              <Card
                title="Resultados esperados"
                description=" Alinhamento dos objetivos e esforços, bem como o adequado
              aproveitamento dos recursos organizacionais."
              />
            </div>
          </section>
        )}
        {selected === 1 && (
          <section>
            <h2>
              <icon.IconInteligenciaComp />
              {navTitleTabs[2]}
            </h2>
            <div className="cards-container">
              <Card
                title="Metodologia"
                description="O processo de construção do Planejamento Empresarial conduzido
              pela Indicador alinha e reorienta os processos de negócios da
              empresa, por meio do desdobramento e monitoramento mensal das
              metas através de indicadores e análise dos resultados
              efetivamente planejados e alcançados."
              />
              <Card
                title="Objetivos"
                description="Reorientação estratégica e organizacional."
              />
              <Card
                title="Resultados esperados"
                description=" Alinhamento dos objetivos e esforços, bem como o adequado
              aproveitamento dos recursos organizacionais."
              />
            </div>
          </section>
        )}
        {selected === 2 && (
          <section>
            <h2>
              <icon.IconPessoas />
              {navTitleTabs[3]}
            </h2>
            <div className="cards-container">
              <Card
                title="Metodologia"
                description="O processo de construção do Planejamento Empresarial conduzido
              pela Indicador alinha e reorienta os processos de negócios da
              empresa, por meio do desdobramento e monitoramento mensal das
              metas através de indicadores e análise dos resultados
              efetivamente planejados e alcançados."
              />
              <Card
                title="Objetivos"
                description="Reorientação estratégica e organizacional."
              />
              <Card
                title="Resultados esperados"
                description=" Alinhamento dos objetivos e esforços, bem como o adequado
              aproveitamento dos recursos organizacionais."
              />
            </div>
          </section>
        )}
        {selected === 3 && (
          <section>
            <h2>
              <icon.IconMarketing />
              {navTitleTabs[4]}
            </h2>
            <div className="cards-container">
              <Card
                title="Metodologia"
                description="O processo de construção do Planejamento Empresarial conduzido
              pela Indicador alinha e reorienta os processos de negócios da
              empresa, por meio do desdobramento e monitoramento mensal das
              metas através de indicadores e análise dos resultados
              efetivamente planejados e alcançados."
              />
              <Card
                title="Objetivos"
                description="Reorientação estratégica e organizacional."
              />
              <Card
                title="Resultados esperados"
                description=" Alinhamento dos objetivos e esforços, bem como o adequado
              aproveitamento dos recursos organizacionais."
              />
            </div>
          </section>
        )}
        {selected === 4 && (
          <section>
            <h2>
              <icon.IconArquiOrg />
              {navTitleTabs[5]}
            </h2>
            <div className="cards-container">
              <Card
                title="Metodologia"
                description="O processo de construção do Planejamento Empresarial conduzido
              pela Indicador alinha e reorienta os processos de negócios da
              empresa, por meio do desdobramento e monitoramento mensal das
              metas através de indicadores e análise dos resultados
              efetivamente planejados e alcançados."
              />
              <Card
                title="Objetivos"
                description="Reorientação estratégica e organizacional."
              />
              <Card
                title="Resultados esperados"
                description=" Alinhamento dos objetivos e esforços, bem como o adequado
              aproveitamento dos recursos organizacionais."
              />
            </div>
          </section>
        )}
        {selected === 5 && (
          <section>
            <h2>
              <icon.IconGestaoPerformance />
              {navTitleTabs[6]}
            </h2>
            <div className="cards-container">
              <Card
                title="Metodologia"
                description="O processo de construção do Planejamento Empresarial conduzido
              pela Indicador alinha e reorienta os processos de negócios da
              empresa, por meio do desdobramento e monitoramento mensal das
              metas através de indicadores e análise dos resultados
              efetivamente planejados e alcançados."
              />
              <Card
                title="Objetivos"
                description="Reorientação estratégica e organizacional."
              />
              <Card
                title="Resultados esperados"
                description=" Alinhamento dos objetivos e esforços, bem como o adequado
              aproveitamento dos recursos organizacionais."
              />
            </div>
          </section>
        )}
        <div className="slider">
          <icon.ChevronLeft className="left" onClick={previous} />
          {Array.from({ length: 6 }, (_, index) => (
            <label
              htmlFor={`slider_${index}`}
              key={index}
              className={`${selected === index && "selected"}`}
            >
              <input
                type="radio"
                id={`slider_${index}`}
                onChange={() => setSelected(index)}
                checked={selected === index}
              />
            </label>
          ))}
          <icon.ChevronRight className="right" onClick={next} />
        </div>
      </section>
      {/* <section className="some-of-our-customers">
        <h2>Estratégia empresarial</h2>
        <nav>
          {navTitleTabs.map((title) => (
            <S.Button
              selected={selected === title}
              onClick={() => setSelected(title)}
            >
              {title}
            </S.Button>
          ))}
        </nav>
        <div className="grid">
          <div className="collum-1">
            <ClientCard image="https://picsum.photos/1920/1080" />
            <ClientCard image="https://picsum.photos/1920/1080" />
            <ClientCard image="https://picsum.photos/1920/1080" />
          </div>
          <div className="collum-2">
            <ClientCard image="https://picsum.photos/1920/1080" />
            <ClientCard image="https://picsum.photos/1920/1080" />
          </div>
          <div className="collum-3">
            <ClientCard image="https://picsum.photos/1920/1080" />
            <ClientCard image="https://picsum.photos/1920/1080" />
            <ClientCard image="https://picsum.photos/1920/1080" />
          </div>
        </div>
      </section> */}
      <DropDownContainer />
    </S.Container>
  );
};
