import * as S from "./styles";

export const Footer = () => {
  return (
    <S.Container>
      <section>
        <div>
          <h3>Indicador Consultores Associados</h3>
          <p>
            2015-{new Date().getFullYear()} Indicador Consultores Associados.
          </p>
          <p>
            Todos os direitos reservados. Recomendamos a visualização deste
            website nas versões Microsoft Internet Explorer 11 ou superior e/ou
            nas últimas versões dos navegadores Google Chrome e Mozilla Firefox.
          </p>
        </div>
        {/* <div>
          <h6>Escolha um país/região</h6>
          <select>
            <option>Português - Brasil</option>
          </select>
        </div> */}
      </section>
      <hr />
      <div style={{ display: "flex", justifyContent: "end" }}>
        <p>Entre em contato: (31) 99296-0713</p>
      </div>
    </S.Container>
  );
};
