import { useState } from "react";
import { ReactComponent as ChevronDown } from "../../assets/Icones/chevron_down.svg";
import * as icon from "../../assets/icons";
import { Container, DropDownSection } from "./styles";

type DropDownProps = {
  title: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  description: string;
  selected: string;
  onClick: () => void;
};

const DropDown = ({
  Icon,
  title,
  description,
  selected,
  onClick,
}: DropDownProps) => {
  return (
    <Container selected={selected === title} onClick={onClick}>
      <Icon className="icon" />
      <div>
        <p>{title}</p>
        <ChevronDown />
      </div>
      <div className="description">
        <p>{description}</p>
      </div>
    </Container>
  );
};

export const DropDownContainer = ({
  ref,
}: {
  ref?: React.RefObject<HTMLElement>;
}) => {
  const [selectedDropDown, setSelectedDropDown] = useState("");

  const selectDropDown = (title: string) => {
    setSelectedDropDown((prev) => (prev === title ? "" : title));
  };

  return (
    <DropDownSection ref={ref}>
      <div>
        <p style={{ lineHeight: "calc(2rem * 1.5)" }}>
          Conheça os diferenciais que tornam a Indicador a mais adequada para
          sua empresa:
        </p>
      </div>
      <div className="drop-down-section">
        <DropDown
          title="Experiência e conhecimento"
          Icon={icon.IconGestaoPerformance}
          description="Há 30 anos, estamos atendendo a mais de 300 empresas e todos os nossos consultores são professores mestres ou doutores em sua área de conhecimento específico."
          selected={selectedDropDown}
          onClick={() => selectDropDown("Experiência e conhecimento")}
        />
        <DropDown
          title="Processo de avaliação sistêmica"
          Icon={icon.IconFilter}
          description="Pensamos negócios e em todas as variáveis que podem afetar os nossos clientes. Cada área de conhecimento é tratada dentro  de uma perspectiva sistêmica."
          selected={selectedDropDown}
          onClick={() => selectDropDown("Processo de avaliação sistêmica")}
        />
        <DropDown
          title="Técnica e embasamento científico"
          Icon={icon.IconSettings}
          description="As nossas soluções são desenvolvidas e fundamentadas em metodologias utilizadas e reconhecidas pelas melhores escolas de negócios do mundo como melhores práticas."
          selected={selectedDropDown}
          onClick={() => selectDropDown("Técnica e embasamento científico")}
        />
        <DropDown
          title="Agilidade, confiabilidade e ética"
          Icon={icon.IconBoard}
          description="Todos os nossos consultores tem como valores a inquietude e a inovação responsável. Temos por princípio que sempre é possível fazer melhor e entregar melhores resultados aos nossos clientes."
          selected={selectedDropDown}
          onClick={() => selectDropDown("Agilidade, confiabilidade e ética")}
        />
        <DropDown
          title="Consultores experientes"
          Icon={icon.IconConsultancy}
          description="Para nós a consultoria é um processo de suprimento intensivo de conhecimento em base temporária, e portanto, tempo e experiência são chave para o sucesso. A nossa equipe é  composta por consultores seniores com experiência executiva e em consultoria. "
          selected={selectedDropDown}
          onClick={() => selectDropDown("Consultores experientes")}
        />
        <DropDown
          title="Atendimento personalizado"
          Icon={icon.IconApps}
          description="Cada cliente é único, e as nossas soluções são construídas e aplicadas a realidade de cada um, considerando-se o seu momento, estrutura, cultura e mercado."
          selected={selectedDropDown}
          onClick={() => selectDropDown("Atendimento personalizado")}
        />
        {/* <DropDown
          title="Soluções criativas e atualizadas"
          Icon={icon.IconLamp}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          selected={selectedDropDown}
          onClick={() => selectDropDown("Soluções criativas e atualizadas")}
        /> */}
      </div>
    </DropDownSection>
  );
};
