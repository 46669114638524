import styled, { css } from "styled-components";

export const Container = styled.section<{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? "#fff" : "#f4f4f4")};
  box-shadow: ${({ selected }) =>
    selected ? "0px 2px 4px #00000029" : "none"};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  height: fit-content;
  min-width: 400px;
  padding: 24px;
  position: relative;
  width: 400px;

  ${({ selected }) =>
    !selected &&
    css`
      :hover {
        background-color: #e5e5e5;
      }
    `}

  .icon {
    height: ${({ selected }) => (selected ? "44px" : "33px")};
    width: ${({ selected }) => (selected ? "44px" : "33px")};
    transition: all 0.3s ease-out;
  }

  .icon path {
    stroke: ${({ selected }) => (selected ? "#E6423D" : "default")};
  }

  div {
    align-items: center;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 2rem;
      font-weight: 300;
    }

    svg {
      transition: all 0.3s ease-out;
      transform: ${({ selected }) =>
        selected ? "rotate(-180deg)" : "rotate(0deg)"};
    }
  }

  .description {
    background-color: ${({ selected }) => (selected ? "#fff" : "#f4f4f4")};
    box-shadow: 0px 2px 4px #00000029;
    height: ${({ selected }) => (selected ? "fit-content" : "0")};
    left: 0;
    overflow: hidden;
    padding: ${({ selected }) => (selected ? "24px" : "0")};
    position: absolute;
    transition: all 0.5s ease;
    top: 200px;
    width: 400px;
    z-index: 10;

    p {
      font-size: 1.4rem;
      font-weight: 300;
      line-height: calc(1.4rem * 1.5);
    }
  }
`;

export const DropDownSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin: 120px 140px;

  > div {
    P {
      width: 380px;
      font-size: 2rem;
      font-weight: 300;
    }
  }

  .drop-down-section {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 1220px;
  }
`;
