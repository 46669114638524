import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Fira Sans', sans-serif;
    font-size: 1.6rem;
  }
  
  html {
    font-size: 62.5%;
  }

  body {
    background-color: #fff;
    padding-top: 76px;
  }

  button {
    border: none;
    cursor: pointer;
    background-color: transparent;
    font-size: 16px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  p {
    line-height: calc(16px * 1.5);
  }

  .learn-more {
    align-items: center;
    color: #e6423d;
    display: flex;
    gap: 26px;
    margin-top: 34px;

    svg {
      transition: all 0.3s ease-out;
    }

    :hover {
      text-decoration: underline;
      svg {
        transform: translateX(5.5px);
      }
    }
  }

  .anchor-with-background {
    align-items: center;
    background-color: #e6423d;
    color: #fff;
    display: flex;
    height: 48px;
    justify-content: space-between;
    padding: 14px 24px;
    width: 240px;

    svg {
      filter: brightness(1000%);
      transition: all 0.3s ease-out;
    }

    :hover {
      background-color: #ff6363;
      svg {
        transform: translateX(5.5px);
      }
    }
  }

  input, textarea {
    outline: none
  }
`;
