import styled from "styled-components";
import { AdobeStock231139383 } from "../../assets/images";

export const Container = styled.div`
  .first-section {
    background-image: linear-gradient(
        to right,
        rgb(255, 255, 255) 30%,
        rgba(0, 0, 0, 0) 100%
      ),
      url(${AdobeStock231139383});
    background-repeat: no-repeat;
    background-size: cover;
    height: 680px;
    padding: 96px 140px;
    width: 100%;

    div {
      width: 660px;

      h2 {
        font-size: 4.8rem;
        font-weight: 200;
        margin-bottom: 152px;
        font-family: "Sora", sans-serif;
      }

      p {
        font-size: 2rem;
        font-weight: 300;
        line-height: calc(2rem * 1.5);
        margin-bottom: 32px;
      }
    }
  }

  nav {
    background-color: #f9f9f9;
    display: flex;
    gap: 40px;
    padding: 0 140px;
    box-shadow: 0px 1px 0px #00000014;

    a {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: 160px;
      justify-content: center;
      text-align: center;
      width: 100%;

      .chevron-down {
        transition: all 0.3s ease-out;
      }

      :hover {
        background-color: #f4f4f4;

        .chevron-down {
          transform: translatey(5.5px);
        }
      }
    }
  }

  .second-section {
    margin: 144px 140px;

    div {
      display: flex;

      .second-section-main {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        margin-left: ${(137 / 1006) * 100}vh;
        width: 100%;
      }

      hr {
        border: 1px solid #e6423d;
        height: 568px;
      }

      .analysis-and-planning {
        max-width: 520px;
        width: 100%;

        h2 {
          font-size: 4.8rem;
          font-weight: 200;
          font-family: "Sora", sans-serif;
          width: 660px;
        }

        img {
          height: 400px;
          margin: 40px 0;
          object-fit: cover;
          width: 100%;
        }

        p {
          font-weight: 300;
          width: 100%;
        }
      }

      .what-we-do {
        margin-top: 155px;
        max-width: 800px;
        width: 100%;

        h6 {
          color: #666666;
          font-size: 1.2rem;
          font-weight: 500;
          margin-bottom: 16px;
          text-transform: uppercase;
        }

        div {
          display: flex;
          flex-wrap: wrap;

          section {
            margin-bottom: 10px;
            width: 350px;
          }

          p {
            font-weight: 300;
          }
        }

        img {
          height: ${(560 / 1006) * 100}vh;
          margin-top: 82px;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }

  .last-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 120px;

    div {
      display: flex;
      flex-direction: column;

      p {
        font-size: 2.4rem;
        font-weight: 300;
        line-height: calc(2.4rem * 1.5);
        width: 810px;
      }
    }
  }
`;
