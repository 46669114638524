import GlobalStyle from "./styles/global";
// import { Header } from "./containers/Header";
import { RenderRoutes } from "./routes";
import { Footer } from "./containers/Footer";

function App() {
  return (
    <>
      <GlobalStyle />
      {/* <Header /> */}
      <RenderRoutes />
      <Footer />
    </>
  );
}

export default App;
