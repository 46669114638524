import styled from "styled-components";
import * as image from "../../assets/images";

export const Container = styled.section`
  .page-title {
    background-image: radial-gradient(
        closest-side at 50% 50%,
        #000000 0%,
        #00000000 100%
      ),
      url(${image.AdobeStock567196067});
    padding: 60px 0;
    text-align: center;

    h1 {
      color: #fff;
      font-size: 3.2rem;
      font-weight: 200;
    }
  }

  h2 {
    font-family: "Sora", sans-serif;
    font-size: 3.2rem;
    font-weight: 200;
  }

  .business-strategy {
    margin: 80px 140px;

    h2 {
      align-items: center;
      display: flex;

      gap: 12px;
      margin-bottom: 48px;

      svg {
        height: 40px;
        width: 40px;
      }
    }

    .cards-container {
      display: flex;
      gap: 40px;
      justify-content: space-between;
    }

    .slider {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 46px;
      gap: 32px;

      label {
        border: 1px solid #707070;
        border-radius: 50%;
        height: 10px;
        width: 10px;

        input {
          display: none;
        }
      }

      .selected {
        background-color: #000;
      }

      svg {
        cursor: pointer;
        transition: all 0.3s ease-out;
      }

      .left {
        :hover {
          transform: translateX(-8px);
        }
      }

      .right {
        :hover {
          transform: translateX(8px);
        }
      }
    }
  }

  .some-of-our-customers {
    margin: 120px 140px;

    img {
      object-fit: cover;
    }

    nav {
      margin-top: 40px;
    }

    .grid {
      margin-top: 40px;

      .collum-1 {
        display: flex;
        justify-content: space-between;
        gap: 30px;

        > div {
          height: 400px;
          width: 50%;
        }

        img {
          height: 100%;
          width: 100%;
        }
      }

      .collum-2 {
        display: flex;
        justify-content: space-between;
        margin: 40px 0;
        gap: 40px;

        > div {
          height: 400px;
          width: 50%;
        }

        img {
          height: 100%;
          width: 100%;
        }
      }

      .collum-3 {
        display: flex;
        justify-content: space-between;
        gap: 30px;

        > div {
          height: 400px;
          width: 30%;
        }

        > div:nth-child(2) {
          width: 50%;
        }

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
`;

export const CardContainer = styled.div`
  box-shadow: 0px 4px 8px #00000029;
  min-height: 320px;
  padding: 32px;
  width: 50%;

  h6 {
    font-size: 2rem;
    font-weight: 300;
  }

  hr {
    background-color: #ddd;
    border: none;
    height: 1px;
    margin-top: 16px;
    margin-bottom: 32px;
  }

  div {
    display: flex;
    flex-direction: column;
    height: calc(100% - 64px - 12px);
    justify-content: space-between;

    p {
      font-weight: 300;
    }
  }
`;

export const Button = styled.button<{ selected: boolean }>`
  padding: 0 24px;
  padding-bottom: 21px;
  border-bottom: ${({ selected }) =>
    selected ? "2px solid #000" : "1px solid #ddd;"};
  font-weight: ${({ selected }) => (selected ? 400 : 300)};
  color: ${({ selected }) => (selected ? "#000" : "#666")};
`;

export const ClientCard = styled.div`
  position: relative;

  .card-description {
    color: #fff;
    height: 100%;
    opacity: 0;
    padding: 32px;
    position: absolute;
    transition: all 0.5s ease;
    top: 0;
    z-index: 1;

    div {
      display: flex;
      justify-content: space-between;

      svg {
        transform: rotate(-45deg);
        path {
          fill: #fff;
        }
      }
    }

    h6 {
      font-size: 2rem;
    }

    h6,
    p {
      font-weight: 300;
    }

    hr {
      margin-bottom: 32px;
      margin-top: 16px;
    }
  }

  :hover {
    .card-description {
      backdrop-filter: blur(10px);
      background: rgba(0, 0, 0, 0.5);
      opacity: 1;
    }
  }
`;
