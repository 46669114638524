import { RiWhatsappLine } from "react-icons/ri";
import { ReactComponent as ChevronDown } from "../../assets/Icones/chevron_down.svg";
import IndicadorLogo from "../../assets/Icones/logo_indicadores.svg";
import * as S from "./styles";

export const Header = ({
  handleScroll,
}: {
  handleScroll: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}) => {
  // const scrollToBottom = () => {
  //   const { scrollHeight } = document.documentElement;
  //   const { clientHeight } = document.documentElement;
  //   const scrollPosition = scrollHeight - clientHeight - 226;

  //   window.scrollTo({
  //     top: scrollPosition,
  //     behavior: "smooth",
  //   });
  // };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <S.Container>
      <button type="button" onClick={scrollToTop}>
        <img src={IndicadorLogo} alt="indicador consultores logo" />
      </button>
      <section>
        <nav>
          <button type="button" onClick={scrollToTop}>
            Home
          </button>
          {/* <a href="#faq" onClick={scrollToBottom}>
            Quem somos
          </a> */}
          <button type="button" className="services">
            Serviços <ChevronDown />
            <ul>
              <li>
                <a href="#card-1" onClick={handleScroll}>
                  Estratégia empresarial
                </a>
              </li>
              <li>
                <a href="#card-2" onClick={handleScroll}>
                  Inteligência competitiva
                </a>
              </li>
              <li>
                <a href="#card-3" onClick={handleScroll}>
                  Gestão estratégica com pessoas
                </a>
              </li>
              <li>
                <a href="#card-4" onClick={handleScroll}>
                  Marketing estratégico
                </a>
              </li>
              <li>
                <a href="#card-5" onClick={handleScroll}>
                  Design organizacional
                </a>
              </li>
              <li>
                <a href="#card-6" onClick={handleScroll}>
                  Gestão da performance
                </a>
              </li>
            </ul>
          </button>

          {/* <a href="centro-de-informação">
            Centro de Informação <ChevronDown />
          </a> */}
          {/* <a href="entre-em-contato">Contato</a> */}
        </nav>
        <a
          href="https://web.whatsapp.com/send?phone=+5531992960713"
          target="_blank"
          rel="noreferrer"
          className="whatsapp"
        >
          <RiWhatsappLine />
          (31) 99296-0713
        </a>
        {/* <div className="icons-container">
          <button type="button">
            <img src={searchIcon} alt="icone de busca" />
          </button>
          <button type="button">
            <img src={loginIcon} alt="icone de login" />
          </button>
        </div> */}
      </section>
    </S.Container>
  );
};

// eslint-disable-next-line no-lone-blocks
{
  /* <ul>
<li>
  <a href="/services?selected=card-1" onClick={handleScroll}>
    Estratégia empresarial
  </a>
</li>
<li>
  <a href="/services?selected=card-2">Inteligência competitiva</a>
</li>
<li>
  <a href="/services?selected=card-3">Gestão de pessoas</a>
</li>
<li>
  <a href="/services?selected=card-4">Marketing estratégico</a>
</li>
<li>
  <a href="/services?selected=card-5">
    Arquitetura organizacional
  </a>
</li>
<li>
  <a href="/services?selected=card-6">Gestão da performance</a>
</li>
</ul> */
}
