/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styled from "styled-components";
import { FiX } from "react-icons/fi";
import { Dispatch, SetStateAction } from "react";
import { TPopup } from ".";

const Container = styled.div`
  .background {
    background-color: #00000099;
    width: 100vw;
    height: 100vh;
    top: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 90;
  }

  section {
    box-shadow: 0px 4px 8px #00000029;
    min-height: 280px;
    padding: 32px;
    width: 950px;
    background-color: #fff;
    z-index: 100;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h6 {
      font-size: 2rem;
      font-weight: 300;
    }

    hr {
      background-color: #ddd;
      border: none;
      height: 1px;
      margin-top: 16px;
      margin-bottom: 32px;
    }

    div:not(:first-of-type) {
      display: flex;
      flex-direction: column;
      height: calc(100% - 64px - 12px);
      justify-content: space-between;

      p {
        font-weight: 300;
      }
    }
  }
`;

type Props = {
  openPopup: Dispatch<SetStateAction<TPopup | undefined>>;
  selectPopup: TPopup;
};

export const Popup = ({ openPopup, selectPopup }: Props) => {
  const description = {
    card1:
      "O processo de planejamento estratégico elaborado pela Indicadores juntamente com o time dos nossos clientes, conduz a uma avaliação crítica da atuação da empresa no mercado, concorrentes, clientes e da cadeia de valor ou ecossistema de negócios, identificando oportunidades de crescimento e definindo objetivos e metas por meio da coleta de informações e diagnósticos construídos sobre bases científicas, que asseguram vantagens competitivas aos nossos clientes.",
    card2:
      "A inteligência competitiva contribui para que os nossos clientes se antecipem aos movimentos do mercado, dos seus concorrentes e às necessidades dos seus clientes. A Indicadores, por meio de levantamento e cruzamento de dados e informações, facilita e subsidia as tomadas de decisões relacionadas aos mercados, clientes, fornecedores e portifólio de produtos e serviços.",
    card3:
      "A gestão estratégica com pessoas deve versar sobre as competências presentes e futuras da organização. O processo de desenvolvimento do time pode ser feito de diversas formas: mentorias, mediações, aconselhamentos, coaching, treinamentos, cursos e palestras, vivências, entre outros. A Indicadores identifica as necessidades de treinamento e ou desenvolvimento das pessoas, com a proposição de soluções adequadas à cultura, aos propósitos e objetivos estratégicos. Elaboramos e aplicamos a metodologia mais adequada, promovendo o desenvolvimento das competências pessoais e organizacionais necessárias, de acordo com os objetivos do trabalho, o perfil dos participantes e a cultura da empresa.",
    card4:
      "A gestão estratégica da força de vendas analisa as tendências do mercado e dos processos de negócios ligados aos competidores, clientes atuais, potenciais, reorientando as estratégias mercadológicas. A Indicadores faz a avaliação diagnóstica das tendências dos mercados consumidores e competidores, bem como dos atuais processos e ações de negócios, construindo juntamente com a sua empresa, os processos de negócios necessários à nova estratégia mercadológica.",
    card5:
      "O (Re) Design Organizacional melhora o aproveitamento dos recursos materiais, financeiros e humanos, por meio da adequada utilização das competências e a justa recompensa por meritocracia e entrega das metas e dos resultados desejados. A Indicadores utiliza-se das melhores práticas empresariais para o redesenho de estruturas organizacionais, a partir da estratégia competitiva, da orientação das necessidades presentes e futuras dos processos, cultura organizacional, competências organizacionais e do planejamento empresarial.",
    card6:
      "A Indicadores implementa a Gestão da performance, elabora a definição dos fatores chave de sucesso a serem avaliados, objetivos e metas de curto médio e longo prazos, e realiza juntamente à empresa, uma avaliação mensal dos resultados alcançados, sistematizando a análise dos processos de negócios.  Fazemos o monitoramento dos resultados, acompanhando continuamente sua gestão, do diagnóstico à avaliação de resultados, extraindo relatórios de desempenho que se tornam base de conhecimento para um novo planejamento de ações e melhor equilíbrio entre as ações e resultados empresariais.",
  };

  return (
    <Container>
      <div
        className="background"
        onClick={() => {
          // event.preventDefault();
          // event.stopPropagation();
          openPopup(undefined);
        }}
      />
      <section>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h6>Saiba mais:</h6>
          <FiX onClick={() => openPopup(undefined)} />
        </div>
        <hr />
        <div>
          <p>{description[selectPopup]}</p>
        </div>
      </section>
    </Container>
  );
};
