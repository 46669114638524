import styled from "styled-components";
import { Dispatch, SetStateAction } from "react";
import { ReactComponent as ArrowRight } from "../../assets/Icones/arrow_right-red.svg";
import { TPopup } from ".";

const Container = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 76px);
  justify-content: space-between;
  overflow: hidden;
  padding: 160px 140px;
  position: relative;
  width: 100%;
  z-index: 1;

  :hover {
    img {
      transform: scale(1.1);
    }
  }

  img,
  div {
    background-image: linear-gradient(117deg, #000000 0%, #00000000 100%);
    height: 100%;
    object-fit: cover;
    position: absolute;
    right: 0;
    transition: transform 0.5s ease;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  section {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 520px;
  }

  p {
    margin-top: 24px;
    margin-bottom: 32px;
    font-weight: 300;
  }

  h2 {
    font-family: "Sora", sans-serif;
    font-size: 4.8rem;
    font-weight: 200;
    width: 800px;
  }

  .icon {
    min-width: 36px;
    min-height: 36px;
  }

  svg {
    filter: brightness(1000%);
  }
`;

type CardProps = {
  backgroundImage: string;
  title: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  description: string;
  id: string;
  openPopup: Dispatch<SetStateAction<TPopup | undefined>>;
};

export const Card = ({
  backgroundImage,
  title,
  Icon,
  description,
  id,
  openPopup,
}: CardProps) => {
  return (
    <Container id={id}>
      <img src={backgroundImage} alt="imagem de fundo" />
      <div />
      <section>
        <section>
          <h2>{title}</h2>
        </section>
        <Icon className="icon" />
        <p>{description}</p>
        <button
          type="button"
          className="anchor-with-background"
          onClick={() => openPopup(id.replace("-", "") as TPopup)}
        >
          Saiba mais <ArrowRight />
        </button>
      </section>
    </Container>
  );
};
