import styled from "styled-components";

export const Container = styled.footer`
  background-color: #f9f9f9;
  padding: 48px 140px;

  p {
    color: #666;
    font-size: 1.4rem;
  }

  section {
    display: flex;
    justify-content: space-between;

    h3 {
      font-size: 2rem;
      font-weight: 300;
      margin-bottom: 16px;
    }

    h6 {
      font-size: 1.4rem;
      font-weight: 500;
    }

    select {
      margin-top: 12px;
      outline: none;
      padding: 16px 14px;
      width: 380px;
    }
  }

  hr {
    background-color: #e5e5e5;
    border: none;
    height: 1px;
    margin-top: 40px;
    margin-bottom: 23px;
  }
`;
